<template>
  <!-- 电子发票管理 -->
  <div ref="electronicInvoiceAdmin" class="electronicInvoiceAdmin">
    <div ref="functionButtons" class="function-buttons"></div>
    <div ref="form" class="form-area">
      <el-form
        :inline="true"
        :model="queryForm"
        label-position="left"
        label-width="85px"
        class="demo-form-inline"
      >
        <el-form-item label="开票时间起：">
          <el-date-picker
            v-model="startTime"
            format="yyyy-MM-dd HH:mm:ss"
            value-format="yyyy-MM-dd HH:mm:ss"
            type="datetime"
            default-time="23:59:59"
            placeholder="选择时间"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="开票时间止：">
          <el-date-picker
            v-model="endTime"
            format="yyyy-MM-dd HH:mm:ss"
            value-format="yyyy-MM-dd HH:mm:ss"
            type="datetime"
            default-time="23:59:59"
            placeholder="选择时间"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="所属公司：">
          <companySelect
            :clearable="true"
            :company-tree="companyList"
            :valuename="valuename"
            :value="value"
            @getValue="getGroupId"
          ></companySelect>
        </el-form-item>
        <el-form-item label="发票号码：">
          <el-input
            v-model="queryForm.fpHm"
            clearable
            placeholder="请输入发票号码"
          ></el-input>
        </el-form-item>
        <el-form-item label="开票类型：">
          <el-select
            v-model="queryForm.type"
            clearable
            placeholder="请选择开票类型"
          >
            <el-option
              v-for="item in openTypeList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="购买名称：">
          <el-input
            v-model="queryForm.buyerName"
            clearable
            placeholder="请输入购买名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="订单类型：">
          <el-select
            v-model="orderType"
            clearable
            multiple
            placeholder="请选择订单类型"
          >
            <el-option
              v-for="item in orderTypeList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="订单编号：">
          <el-input
            v-model="queryForm.orderId"
            clearable
            placeholder="请输入订单编号"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="small" @click="queryFun"
            >查询</el-button
          >
          <el-button type="primary" size="small" @click="exportFun"
            >导出</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <Table
      :table-data="tableData"
      :title-name="titleName"
      :col-class-type="true"
      :operation="true"
      :table-height="TableHeight"
    >
      <template #default="{ scopeRow }">
        <el-button
          v-if="
            scopeRow.type == 'blue' && scopeRow.status === 0 && listFind('冲红')
          "
          type="text"
          @click="changeRed(scopeRow)"
          >冲红</el-button
        >
        <span v-else>-</span>
      </template>
    </Table>
    <div ref="pagination" class="pagination">
      <el-pagination
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        :page-size="queryForm.pageSize"
        :current-page="queryForm.currentPage"
        @size-change="onSizeChange"
        @current-change="onCurrentChange"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import { lastTime } from "@/tools/getTime.js";
import companySelect from "@/components/treeSelect/companySelect.vue";
import {
  invoceListAPI,
  invoceListExpAPI,
  getCompanyTree,
  issueRedInvoiceAPI,
} from "@/api/lib/api.js";
export default {
  components: {
    companySelect,
  },
  data() {
    return {
      tableData: [],
      TableHeight: 0,
      TableHeight2: 0,
      titleName: [
        {
          title: "发票请求流水号",
          props: "fpqqlsh",
          width: 200,
        },
        {
          title: "联系电话",
          props: "telephone",
          width: 120,
        },
        {
          title: "线路",
          props: "lineName",
          width: 200,
        },
        {
          title: "开票类型",
          props: "type",
          SpecialJudgment: (res) => {
            return res == "blue" ? "蓝字发票" : "红字发票";
          },
        },
        {
          title: "开票日期",
          props: "kprq",
          width: 140,
          SpecialJudgment: (res) => {
            return res.replace(
              /(\d{4})(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})/g,
              "$1-$2-$3 $4:$5:$6"
            );
          },
        },
        {
          title: "PDF下载地址",
          // props: "pdfUrl",
          width: 120,
          render: (h, params, index) => {
            return (
              <a href={params.pdfUrl} style="text-decoration:none;color:red">
                下载
              </a>
            );
          },
        },
        {
          title: "发票代码",
          props: "fpDm",
          width: 130,
        },
        {
          title: "发票号码",
          props: "fpHm",
          width: 100,
        },

        {
          title: "价税合计单位(元)",
          props: "jshj",
          width: 200,
        },
        {
          title: "合计金额(元)",
          props: "hjje",
          width: 120,
        },
        {
          title: "合计税额",
          props: "hjse",
        },
        {
          title: "备注",
          props: "userRemark",
        },
        {
          title: "原发票代码",
          props: "yfpDm",
          width: 120,
        },
        {
          title: "原发票号码",
          props: "yfpHm",
          width: 120,
        },
        {
          title: "销售方纳税人识别号",
          props: "xhdwdm",
          width: 200,
        },
        {
          title: "销售方名称",
          props: "xhdwmc",
          width: 120,
        },
        {
          title: "购买方纳税人识别号",
          props: "buyerTaxNumber",
          width: 200,
        },
        {
          title: "购买方名称",
          props: "buyerName",
          width: 120,
        },
        {
          title: "购方客户邮箱",
          props: "buyerEmail",
          width: 120,
        },
        {
          title: "微信取票地址",
          props: "wxurl",
          width: 120,
        },
        {
          title: "订单类型",
          props: "orderType",
          SpecialJudgment: (res) => {
            return res == 1
              ? "定制客运"
              : res == 2
              ? "汽车订票"
              : res == 6
              ? "定制班线"
              : res == 100
              ? "购票服务费"
              : "";
          },
        },
        {
          title: "订单编号",
          props: "orderId",
        },
        {
          title: "是否重开",
          props: "reopen",
          SpecialJudgment: (res) => {
            return res == 0 ? "是" : "否";
          },
        },
      ],
      orderType: [],
      startTime: null,
      endTime: null,
      queryForm: {
        startTime: "",
        endTime: "",
        orderType: "",
        platformCompany: "",
        buyerName: "",
        fpHm: "",
        pageSize: 10,
        currentPage: 1,
        type: "",
        orderId: "",
      },
      total: 0,
      companyList: [],
      value: null,
      valuename: null,
      openTypeList: [
        {
          value: "red",
          label: "红字发票",
        },
        {
          value: "blue",
          label: "蓝字发票",
        },
      ],
      orderTypeList: [
        {
          value: 2,
          label: "汽车订票",
        },
        {
          value: 1,
          label: "城际专车",
        },
        {
          value: 6,
          label: "定制班线",
        },
        {
          value: 100,
          label: "购票服务费",
        },
      ],
      exportForm: {},
    };
  },
  methods: {
    queryFun() {
      if (!this.startTime) {
        this.queryForm.startTime = "";
      } else {
        this.queryForm.startTime = new Date(this.startTime).getTime();
      }
      if (this.endTime) {
        this.queryForm.endTime = new Date(this.endTime).getTime();
      } else {
        this.queryForm.endTime = "";
      }
      this.queryForm.orderType = this.orderType.join();
      this.exportForm = this.deepClone(this.queryForm);
      this.queryForm.currentPage = 1;
      this.renderData();
    },
    // 计算表格高度
    computeHeight() {
      const wholeHeight = this.$refs.electronicInvoiceAdmin.clientHeight;
      this.TableHeight = wholeHeight - 270 + "px";
      this.TableHeight2 = wholeHeight - 240;
    },
    exportFun() {
      invoceListExpAPI(this.exportForm).then((res) => {
        const blob = new Blob([res], { type: "application/vnd.ms-excel" });
        const fileName =
          "电子发票导出订单 " + this.GMTToStr(new Date()) + ".xlsx"; //文件名
        const linkNode = document.createElement("a");

        linkNode.download = fileName; //a标签的download属性规定下载文件的名称
        linkNode.style.display = "none";
        linkNode.href = URL.createObjectURL(blob); //生成一个Blob URL
        document.body.appendChild(linkNode);
        linkNode.click(); //模拟在按钮上的一次鼠标单击

        URL.revokeObjectURL(linkNode.href); // 释放URL 对象
        document.body.removeChild(linkNode);
      });
    },
    renderData() {
      invoceListAPI(this.queryForm).then((res) => {
        if (res.code == "SUCCESS") {
          this.tableData = res.data.list;
          this.total = res.data.total;
        }
      });
    },
    // 分页页数发生变化
    onCurrentChange(val) {
      this.queryForm.currentPage = val;
      this.exportForm = this.deepClone(this.queryForm);
      this.renderData();
    },
    // 分页change方法
    onSizeChange(val) {
      this.queryForm.pageSize = val;
      this.queryForm.currentPage = 1;
      this.exportForm = this.deepClone(this.queryForm);
      this.renderData();
    },
    getCompanyList() {
      getCompanyTree({ parentId: sessionStorage.getItem("companyId") }).then(
        (res) => {
          if (res.code === "SUCCESS") {
            this.companyList = res.data;
          }
        }
      );
    },
    getGroupId(v) {
      if (v == null) {
        this.queryForm.platformCompany = null;
      } else {
        this.queryForm.platformCompany = v;
      }
    },
    changeRed(scope) {
      this.$confirm("发票冲红后，该订单不可再次开票", "确认冲红该发票", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        center: true,
      }).then(() => {
        issueRedInvoiceAPI(scope.id).then((res) => {
          if (res.code == "SUCCESS") {
            this.$message.success("该发票已冲红");
            this.queryFun();
          }
        });
      });
    },
  },
  mounted() {
    // 时间计算，默认一个月
    this.startTime = lastTime(new Date()) + " 00:00:00";
    let day = new Date();
    this.endTime =
      day.getFullYear() +
      "-" +
      (day.getMonth() + 1) +
      "-" +
      day.getDate() +
      " 23:59:59";
    this.exportForm = this.deepClone(this.queryForm);
    this.queryFun();
    this.getCompanyList();
    this.$nextTick(() => {
      this.computeHeight();
      window.addEventListener("resize", this.computeHeight, false);
    });
  },
};
</script>

<style scoped lang="scss">
.electronicInvoiceAdmin {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  padding: 4px 20px;
  box-sizing: border-box;
  background: #ffffff;
}
.Table {
  margin-top: 16px;
}
/deep/.el-form {
  z-index: 999;
}
/deep/.download {
  text-decoration: none;
}
</style>
